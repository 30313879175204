.App {
  background-color: #2a3d5c;
  color: white;
}

/* ---------------header-------------------- */

.Page-header {
  color: #89d7ea;
}

.navbar-brand {
  max-width: 4rem;
  margin-right: 2rem;
}

.navbar-brand img {
  width: 100%;
  height: 100%;
  margin-right: 2rem;
  max-width: 4rem;
}

.navbar-dark {
  background-color: #001732;
  margin-bottom: 2rem;
  min-height: 6rem;
}

.nav-item > a {
  padding: 10px;
  color: white;
  text-decoration: none;
}

.nav-item > a:hover {
  color: gray;
}

/* ---------------header-------------------- */

/* ---------------project-------------------- */

.project-header {
  font-family: "Tahoma", sans-serif;
}

.carousel-img {
  width: auto;
  max-height: 250px;
}

.swiper-slide {
  max-height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ---------------project-------------------- */
